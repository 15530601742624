export const environment = {
  production: false,
  authDomain: 'beta.novels-ai.com',
  API_URL_ADD_CHAPTER: 'https://add-chapter-i3rsqvn75a-lm.a.run.app/',
  API_URL_GENERATE_NEW_NOVEL: 'https://generate-audiobook-i3rsqvn75a-lm.a.run.app/',
  API_URL_REMOVE_NOVEL: 'https://delete-audiobook-i3rsqvn75a-lm.a.run.app/',
  
  STORIES_PER_PAGE: 5,
  NEW_USER_POINTS: 1,
  SUBSCRIPTION_PRICE_ID: 'price_1QK3rEBPvg43OlrWCaP4YQ0w',
  STRIPE_TEST_MODE: true,
  POINTS_PRICE_ID: 'price_1MvO0jBPvg43OlrWpKdsLptb',
};
